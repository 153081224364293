import * as React from "react"
import Markdown from "react-markdown"

export interface AboutProps {
    content: string,
    imageUrl: string
}


const style = {
    container: {
        fontFamily: "Cutive Mono",
        display: "flex",
        justifyContent: "center",
        color: "rgb(102, 102, 102)"
    } as React.CSSProperties,
    textContainer: {
        maxWidth: "80vw"
    } as React.CSSProperties
}

const About:React.FC<AboutProps> = ({content, imageUrl}) => {
    return (
        <div style={style.container}>
            <div style={style.textContainer}>
            <Markdown source={content}/>
            <img style={style.textContainer} src={imageUrl}/>
            </div>
        </div>
    )
}

export default About
