export default `
You want to exchange love letters?

For business inquiries and love letters, send me an mail via [hallo@christinekrawall.de](mailto:hallo@christinekrawall.de)

I’m looking forward to meet you and hear about your ideas; wether it is a private or commercial project.

Love always,

Christine
`
