import * as React from "react"
import Header from "../components/header"
import Gallery from "../components/gallery"

const Home = () => (
    <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{maxWidth: "1200px"}}>
         <Gallery />
        </div>
    </div>
)

export default Home
