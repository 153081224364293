import * as React from "react"
import Navigation from "../navigation"
import {Container} from "@material-ui/core"

export interface HeaderProps {
    title: string
    titleLink?: string
    subtitle?: string
}

const colors = {
    gray: "rgb(102, 102, 102)",
    lightGray: "rgb(174, 174, 174)"

}


const style = {
    container: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "40px"
    } as React.CSSProperties,
    titleContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "60px",
        marginBottom: "60px"
    } as React.CSSProperties,
    titleText: {
        fontSize: "50px",
        fontFamily: "Oswald",
        fontWeight: 300,
        color: colors.gray,
        textDecoration: "none"
    } as React.CSSProperties,
    subtitle: {
        fontFamily: "Cutive Mono",
        color: colors.lightGray,
        fontSize: "12px"
    } as React.CSSProperties
}

const links = [
    {
        label: "portfolio",
        href: "/"
    },
    {
        label: "about",
        href: "/about"
    },
    {
        label: "contact",
        href: "/contact"
    }
]


const Header: React.FC<HeaderProps> = ({ title,titleLink, subtitle }) => (
    <div>
        <div style={style.titleContainer}>
            <a style={style.titleText} href={titleLink}>{title}</a>
        </div>
    <Navigation links={links}/>
    <div style={style.container}>
    <p style={style.subtitle}>{subtitle}</p>
    </div>
    </div>
)

export default Header
