import * as React from "react"
import Markdown from "react-markdown"

export interface GenericProps {
    content: string
}

const style = {
    container: {
        fontFamily: "Cutive Mono",
        display: "flex",
        justifyContent: "center",
        color: "rgb(102, 102, 102)"
    } as React.CSSProperties,
    textContainer: {
        maxWidth: "80vw"
    } as React.CSSProperties
}

const Generic: React.FC<GenericProps> = ({ content }) => (
    <div style={style.container}>
        <div style={style.textContainer}>
            <Markdown source={content}/>
        </div>
    </div>
)

export default Generic
