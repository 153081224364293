import * as React from 'react'
import Home from "./screens/home"
import About from "./screens/about"
import Generic from "./screens/generic"
import Header from "./components/header"
import Footer from "./components/footer"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import content from "./content"
import ContactContent from "./content/contact"
import AboutContent from "./content/about"
import NotFoundContent from "./content/404"

const style = {
    container: {
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column"
    } as React.CSSProperties,
    content: {
        flex: 1
    }
}

const App: React.FC = () => {
    return (
        <Router>
        <div style={style.container}>
        <div style={style.content}>
        <Header
            title={content.general.title}
            titleLink={content.general.titleLink}
            subtitle={content.general.subline}
        />
        <Switch>
            <Route exact path="/about">
              <About imageUrl={content.about.imageUrl} content={AboutContent} />
            </Route>
            <Route exact path="/contact">
              <Generic content={ContactContent}/>
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="*">
                <Generic content={NotFoundContent}/>
            </Route>
          </Switch>
          </div>
          <Footer content={content.general.copyright}/>
        </div>
      </Router>
    )
}

export { App }
