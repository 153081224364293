import * as React from 'react'
import { listItems } from '../../services/cloud-storage'
import Masonry from 'react-masonry-css'

const Gallery = () => {

    const [images, setImages] = React.useState([])

    React.useEffect(() => {
        const fetch = async () => setImages(await listItems("christinekrawall-de-public", "portfolio"))
        fetch()
    }, [])

    const breakpointColumnsObj = {
        default: 2,
        900: 2,
        800: 1
      };
    return (
        <div className="gallery">
            <Masonry
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
                breakpointCols={breakpointColumnsObj}
                >
                {images.map((item, index) =>
                    <div className="image-container" key={index}>
                        <img className="grid-image" src={item} alt="" />
                    </div>
                )}
            </Masonry>
        </div>
    )
}

export default Gallery
