import * as React from "react"


export interface FooterProps {
    content: string
}

const style = {
    container: {
        fontFamily: "Cutive Mono" ,
        color: "rgb(174, 174, 174)",
        display: "flex",
        justifyContent: "center",
    } as React.CSSProperties
}

const Footer:React.FC<FooterProps> = ({content}) => (
    <div style={style.container}>
        <p>{content}</p>
    </div>
)

export default Footer
