import * as React from "react"
import {Link} from "react-router-dom"

export interface LinksProps {
    links: LinkProp[]
}

export interface LinkProp {
    label: string,
    href: string
}
const style = {
    link: {
        padding: "20px",
        fontFamily: "Cutive Mono" ,
        color: "rgb(174, 174, 174)",
        textDecoration: "none"
    } as React.CSSProperties,
    container: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "20px",
        paddingBottom: "20px"
    }

}
const Navigation:React.FC<LinksProps> = ({links}) => {
    return (
        <div style={style.container}>
            <div>
            {
                links.map((item,index) => (
                    <a key={index} style={style.link} href={item.href}>{item.label}</a>
                ))
            }
            </div>
        </div>
    )
}

export default Navigation
