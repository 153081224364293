const storagePublicUrl = "https://storage.googleapis.com"

const listItems = async (bucket: string, folder: string) => {
    const resp = await fetch(`https://www.googleapis.com/storage/v1/b/${bucket}/o?prefix=${folder}`)
    const json = await resp.json()
    let data = json.items.filter(item => item.contentType !=="application/octet-stream")
    data = data.filter((item) => item.name != `${folder}/`)
    return data.map((item) => `${storagePublicUrl}/${bucket}/${item.name}`)
}

export { listItems }
