export default {
    general: {
        title: "Christine Krawall",
        titleLink: "/",
        subline: "< get as close to the soul as possible >",
        copyright: "© 2014–2019 Christine Krawall All rights reserved",
    },
    about: {
        imageUrl: "https://storage.googleapis.com/christinekrawall-de-public/about.jpg",
    }
}
